<template>
  <div>
    <header class="page-header" v-if="!isLoading">
      <PageTitle/>
      <div class="buttons">
        <button @click="openModal" class="btn btn-add" v-if="checkPermissions(['role.create', 'permission.view'])">
          <span>{{ $t('Gebruikersrol toevoegen') }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <div v-if="roles.length > 0" class="table-wrapper">
          <div class="table-element">
            <table class="roles">
              <thead>
              <tr>
                <th class="id">{{ $t('ID') }}</th>
                <th>{{ $t('Naam') }}</th>
              </tr>
              </thead>

              <tbody v-if="roles.length > 0">
              <tr :key="role.id" @contextmenu.prevent.stop="roleRightClick($event, role)" @click="fetchRole(role.id)" v-for="role in roles">
                <td class="id">{{ role.id }}</td>
                <td>{{ role.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ total.min }} - {{ total.max }} van {{ $store.state.RolesModule.rolesTotal }} gebruikersrollen
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">{{ $t('Prev') }}</button>
              <button class="btn-inverse" :disabled="roles.length !== perPage" @click="nextPage()">{{ $t('Next') }}</button>
            </div>
          </div>
        </div>
        <div class="no-results" v-else>{{ $t('Geen resultaten') }}</div>

        <modal class="modal roles-modal" name="roleModal" :width="770" height="auto" :adaptive="true" :scrollable="true">
          <button @click="closeModal" class="btn-close"><img src="@/assets/img/close.svg" alt="Close"></button>

          <div class="content" v-if="!isLoading">
            <formulate-form ref="appointmentsForm" name="appointmentsForm" v-model="formValues" @submit="saveRole()">
              <h2>{{ formValues.id ? $t('Gebruikersrol wijzigen') : $t('Gebruikersrol toevoegen') }}</h2>
              <formulate-input validation="required" error-behavior="submit" type="text" name="name" label="Naam"></formulate-input>
              <formulate-input :options="permissions" error-behavior="submit" type="checkbox" name="permissions"></formulate-input>
              <formulate-input input-class="btn" type="submit" :label="isLoading ? $t('Even geduld...') : $t('Opslaan')"></formulate-input>
            </formulate-form>
          </div>
        </modal>
      </div>
    </div>

    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'Roles',
  components: { PageTitle },
  data: function () {
    return {
      isLoading: false,
      formValues: {},
      contextMenuOptions: [{ id: 'deleteRole', name: 'Rol verwijderen' }]
    }
  },
  async mounted () {
    this.isLoading = true

    if (this.checkPermissions(['permission.view']) && (this.checkPermissions(['role.create']) || this.checkPermissions(['role.edit']))) {
      await this.$store.dispatch('RolesModule/fetchPermissions')
    }

    await this.$store.dispatch('RolesModule/fetchRoles')

    this.isLoading = false
  },
  methods: {
    roleRightClick: async function (event, role) {
      if (this.checkPermissions(['role.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, role)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteRole(event.item.id)
    },
    fetchRole: async function (roleId) {
      if (!this.checkPermissions(['permission.view', 'role.edit'])) {
        return false
      }

      this.openModal(this.roles.find((role) => role.id === roleId))
    },
    deleteRole: async function (roleId) {
      await ApiService.deleteRole(roleId).then(async (res) => {
        notification(this.$t('Gebruikersrol succesvol verwijderd.'))
        await this.$store.dispatch('RolesModule/fetchRoles')
      })
    },
    saveRole: async function () {
      const permissions = []

      this.formValues.permissions.forEach((p, i) => {
        const exp = p.split('.')

        const permission = {
          entity: exp[0],
          action: exp[1]
        }

        permissions.push(permission)
      })

      this.formValues.permissions = permissions

      ApiService.saveRole(this.formValues).then(async res => {
        await this.$store.dispatch('RolesModule/fetchRoles')
        this.closeModal()
        notification(this.formValues.id === 'post' ? 'Gebruikersrol succesvol toegevoegd!' : 'Gebruikersrol succesvol aangepast!')
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        await this.$store.dispatch('RolesModule/fetchRoles', { page: this.page - 1, perPage: this.perPage })
      }
    },
    nextPage: async function () {
      if (this.roles.length === this.perPage) {
        await this.$store.dispatch('RolesModule/fetchRoles', { page: this.page + 1, perPage: this.perPage })
      }
    },
    openModal: function (role = null) {
      this.formValues = {}
      this.formValues.permissions = []

      if (role && role.id) {
        this.formValues.id = role.id
        this.formValues.name = role.name

        if (role.permissions.length > 0) {
          role.permissions.forEach((permissionRow, i) => {
            this.formValues.permissions.push(permissionRow.entity + '.' + permissionRow.action)
          })
        }
      }

      this.$modal.show('roleModal')
    },
    closeModal: function () {
      this.$modal.hide('roleModal')
    }
  },
  computed: {
    page: function () {
      return this.$store.state.RolesModule.page
    },
    perPage: function () {
      return this.$store.state.RolesModule.perPage
    },
    roles: function () {
      const rolesData = this.$store.getters['RolesModule/getRoles']
      let roles = []

      if (rolesData) {
        roles = rolesData.map((roleRow) => {
          const role = {}

          for (const prop in roleRow) {
            if ((prop === 'created' || prop === 'last_login') && roleRow[prop] != null) {
              role[prop] = DateTime.fromISO(roleRow[prop])
            } else {
              role[prop] = roleRow[prop]
            }
          }

          return role
        })
      }

      return roles
    },
    permissions: function () {
      const permissions = {}

      if (this.$store.getters['RolesModule/getPermissions'].length > 0) {
        this.$store.getters['RolesModule/getPermissions'].forEach((permissionRow) => {
          permissions[permissionRow.entity + '.' + permissionRow.action] = permissionRow.label
        })
      }

      return permissions
    },
    total: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.$store.state.RolesModule.rolesTotal) {
        offsetMax = this.$store.state.RolesModule.rolesTotal
      }

      return {
        min: offsetMin,
        max: offsetMax
      }
    }
  }
}
</script>
